import * as React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Main from '../components/Main';
import Localize from '../components/Localize';

const IndexPage = ({ pageContext, location, data }) => {
	//console.log(data);
	const { sanityInfo } = data;
	const { title, description } = sanityInfo;

	return (
		<Layout
			data={data}
			pageContext={pageContext}
			location={location}
			fromIndex={true}
		>
			<Main
				title={title}
				description={description}
				information=""
				fromIndex={true}
			/>
		</Layout>
	);
};

export const query = graphql`
	query {
		sanityInfo(id: { eq: "-e9b2d7f7-f42b-5f47-ad87-ac46d4e3cd1a" }) {
			id
			title
			description {
				_type
				de: _rawDe
				en: _rawEn
			}
		}
		allSanityArtwork {
			edges {
				node {
					_id
					location {
						lat
						lng
					}
					title
					artwork {
						image {
							asset {
								gatsbyImageData
							}
						}
						alt {
							_key
							_type
							de
							en
						}
						caption {
							_key
							_type
							de
							en
						}
					}
					slug {
						current
					}
				}
			}
		}
	}
`;

export default Localize(IndexPage);
